import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { filter, find, get, includes, isEmpty, map, some, sumBy } from "lodash";
import { push } from "connected-react-router";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { registerKYB } from "actions/adminActions";
import Button from "components/Button";
import { toast } from "react-toastify";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { wrapError } from "utils/errorHelper";
import { Link } from "react-router-dom";
import Alert from "components/Alert";

import {
  getCompany,
  getCompanyKybData,
  getCompanyMembers,
  removeCompanyMember,
} from "services/adminService";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { BsPencilSquare, BsTrash } from "react-icons/bs";

import InfoTable from "components/InfoTable";
import {
  BeneficialOwner,
  ControllingOfficer,
  ControllingOfficerAndBeneficialOwner,
  naicsValues,
} from "statics/businessCodes";
import IconSpinner from "components/IconSpinner";
import SimpleModal from "components/SimpleModal";
import {
  EmployerApplicationAdditionalDocumentationSubmitted,
  EmployerApplicationDenied,
  EmployerApplicationRequiresAdditionalDocumentation,
  EmployerApplicationSubmittedKYB,
} from "statics/states";
import KycJsonViewer from "pages/dashboards/adminDashboard/employers/KycJsonViewer";

const SsnLabel = ({ ssn = "" }) => {
  const [visible, setVisible] = useState(false);

  const maskedSsn = `***-**-${ssn.substr(7)}`;

  const ssnToShow = visible ? ssn : maskedSsn;
  const icon = visible ? (
    <AiFillEyeInvisible size={16} onClick={() => setVisible(false)} />
  ) : (
    <AiFillEye size={16} onClick={() => setVisible(true)} />
  );

  return (
    <span>
      {ssnToShow}
      {icon}
    </span>
  );
};
SsnLabel.propTypes = {
  ssn: PropTypes.string,
};

const companyMetadata = [
  {
    key: "name",
    label: "Company Name",
  },
  {
    key: "dba",
    label: "DBA",
  },
  {
    key: "ein",
    label: "EIN",
  },
  {
    key: "type",
    label: "Incorporation Type",
  },
  {
    key: "naicsCode",
    label: "Category",
    format(code) {
      const matchingCategory = find(naicsValues, { value: code }) || {};
      return `${matchingCategory.category} - ${matchingCategory.description}`;
    },
  },
  {
    key: "address.0",
    label: "Address",
    format(address = {}) {
      if (isEmpty(address)) {
        return "";
      }
      return `${address.address1}${
        address.address2 && ", " + address.address2
      }, ${address.city}, ${address.state} ${address.postalCode} `;
    },
  },
  {
    key: "address.0.phone",
    label: "Phone Number",
  },
  {
    key: "url",
    label: "Website",
  },
  {
    key: "silaWalletId",
    label: "Sila Wallet ID",
  },
];

const memberMetadata = [
  {
    key: "memberProfile",
    label: "Name",
    format(profile) {
      return `${profile.firstName} ${profile.lastName}`;
    },
  },
  {
    key: "memberProfile.email",
    label: "Email",
  },
  {
    key: "memberProfile.dob",
    label: "Date of Birth",
  },
  {
    key: "memberProfile.ssn",
    label: "SSN",
    format(ssn) {
      if (ssn) {
        return <SsnLabel ssn={ssn} />;
      }

      return "";
    },
  },
  {
    key: "role",
    label: "Role",
    format(role) {
      return roleToEnglishMapping[role];
    },
  },
  {
    key: "ownershipStake",
    label: "Ownership Stake",
    format(stake) {
      return stake;
    },
  },
  {
    key: "memberProfile",
    label: "Address",
    format(address = {}) {
      if (isEmpty(address)) {
        return "";
      }
      return `${address.address1}${
        address.address2 && ", " + address.address2
      }, ${address.city}, ${address.state} ${address.postalCode} `;
    },
  },
  {
    key: "memberProfile.phoneNumber",
    label: "Phone Number",
  },
  {
    key: "businessMemberHandle",
    label: "Member ID",
  },
];

const roleToEnglishMapping = {
  ControllingOfficer: "Controlling Officer",
  BeneficialOwner: "Beneficial Owner",
  ControllingOfficerAndBeneficialOwner:
    "Controlling Officer and Beneficial Owner",
};

class Summary extends React.Component {
  static propTypes = {
    companyId: PropTypes.string,
    client: PropTypes.shape({}),
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    registerKYB: PropTypes.func,
    push: PropTypes.func,
    companyState: PropTypes.string,
    canEdit: PropTypes.bool,
    canSubmit: PropTypes.bool,
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      companyInfo: {},
      kybInfo: {},
      members: [],
      customError: "",
      isFetching: true,
      showConfirmationModal: false,
    };
  }

  async componentDidMount() {
    const { companyInfo, members, kybInfo } = await Promise.props({
      members: getCompanyMembers(this.props.client, this.props.companyId),
      companyInfo: getCompany(this.props.client, this.props.companyId),
      kybInfo: getCompanyKybData(this.props.client, this.props.companyId),
    });

    this.setState(
      {
        company: {
          ...companyInfo,
          silaWalletId: get(kybInfo, "silaWallet.accountNumber", ""),
        },
        members,
        kybInfo,
        isFetching: false,
      },
      () => this._validateMembers()
    );
  }

  _validateMembers = () => {
    const controllingOfficers = filter(this.state.members, (member) => {
      return (
        member.role === ControllingOfficer ||
        member.role === ControllingOfficerAndBeneficialOwner
      );
    });
    if (isEmpty(controllingOfficers)) {
      this.setState({ customError: "Company must have a controlling officer" });
      return false;
    }

    if (controllingOfficers.length > 1) {
      this.setState({
        customError: "Company must only have one controlling officer",
      });
      return false;
    }

    const stakeHolders = filter(this.state.members, (member) => {
      return (
        member.role === BeneficialOwner ||
        member.role === ControllingOfficerAndBeneficialOwner
      );
    });

    if (!isEmpty(stakeHolders)) {
      const numOfStakeHolders = stakeHolders.length;
      const totalStake = sumBy(stakeHolders, "ownershipStake");
      if (totalStake > 100) {
        this.setState({
          customError: "Ownership stake must not be greater than 100%",
        });
        return false;
      }

      const hasStakeHolderLessThan25 = some(stakeHolders, (stakeHolder) => {
        return stakeHolder.ownershipStake < 25;
      });

      if (hasStakeHolderLessThan25) {
        this.setState({
          customError: "All stakeholders must have at least 25% stake",
        });
        return false;
      }

      if (numOfStakeHolders === 4 && totalStake !== 100) {
        this.setState({
          customError:
            "Ownership stake must be 100% if there are 4 stakeholders",
        });
        return false;
      }
    }

    return true;
  };

  submitCompany = async () => {
    let employer = {
      employerId: this.props.companyId,
    };

    this.props
      .registerKYB(this.props.client, employer)
      .then(() => {
        toast.success("Successfully submitted company for KYB.");
        this.props.push(`/employers/${this.props.companyId}`);
      })
      .catch((err) => {
        toast.error(wrapError(err));
      });
  };

  _buildOwnersSection() {
    return map(this.state.members, (member) => {
      const popover = (
        <Popover className="action-popover">
          <Popover.Content>
            <p>Are you sure you want to remove member?</p>
            <div>
              <Button
                type="button"
                name="cancel"
                color="cancel"
                btnLabel="Cancel"
                onClick={() => document.body.click()}
                size="sm"
              />
              <Button
                name="submit"
                type="button"
                color="red"
                btnLabel="Remove"
                withArrow
                loading={this.state.isDeleting}
                onClick={() => {
                  this.setState({ isDeleting: true });
                  removeCompanyMember(this.props.client, {
                    companyId: this.props.companyId,
                    memberId: member.id,
                  })
                    .then(() => {
                      return getCompanyMembers(
                        this.props.client,
                        this.props.companyId
                      ).then((members) => {
                        this.setState({ members, isDeleting: false });
                        toast.success("Succesfully removed member.");
                      });
                    })
                    .catch(() => {
                      toast.error(
                        "Something went wrong while attempting to removing member"
                      );
                    });
                }}
                size="sm"
              />
            </div>
          </Popover.Content>
        </Popover>
      );
      return (
        <div className="edit-wrapper">
          <InfoTable metadata={memberMetadata} data={member} />
          {this.props.canEdit && (
            <>
              <BsPencilSquare
                className="edit-icon"
                size={20}
                color="#595A5C"
                onClick={() =>
                  this.props.push(
                    `/employers/${this.props.companyId}/kyb/owners?memberId=${member.id}`
                  )
                }
              />
              <OverlayTrigger
                rootClose
                trigger="click"
                placement="bottom"
                overlay={popover}
              >
                <BsTrash className="trash-icon" size={20} color="#595A5C" />
              </OverlayTrigger>
            </>
          )}
        </div>
      );
    });
  }

  _preValidateData = () => {
    const membersValid = this._validateMembers();

    if (membersValid) {
      this.setState({ showConfirmationModal: true });
    }
  };

  render() {
    if (this.state.isFetching) {
      return <IconSpinner centered />;
    }

    const canViewStatus = includes(
      [
        EmployerApplicationDenied,
        EmployerApplicationAdditionalDocumentationSubmitted,
        EmployerApplicationRequiresAdditionalDocumentation,
        EmployerApplicationSubmittedKYB,
      ],
      this.state.company.currentState
    );

    return (
      <div
        className="tab-pane"
        id="company"
        role="tabpanel"
        aria-labelledby="company_tab"
      >
        <div className="mega-container">
          {this.state.showKycStatusModal && (
            <KycJsonViewer
              companyId={this.props.companyId}
              onClose={() =>
                this.setState({
                  showKycStatusModal: false,
                })
              }
            />
          )}
          {this.state.showConfirmationModal && (
            <SimpleModal
              show={true}
              onClose={() => this.setState({ showConfirmationModal: false })}
              onSubmit={this.submitCompany}
              title="Submit KYB"
              buttonText="Submit"
              closeButtonText="Cancel"
              isXhrRequest={true}
              error={this.props.error}
              isFetching={this.props.isFetching}
              btnDisabled={this.props.isFetching}
            >
              Are you sure you want to submit company for KYB?
            </SimpleModal>
          )}
          <h4 className="page-title">Company</h4>
          <div className="edit-wrapper">
            <InfoTable metadata={companyMetadata} data={this.state.company} />
            {this.props.canEdit && (
              <BsPencilSquare
                className="edit-icon"
                size={20}
                qw
                color="#595A5C"
                onClick={() =>
                  this.props.push(`/employers/${this.props.companyId}/kyb/info`)
                }
              />
            )}
          </div>

          <h4 className="page-title">
            Controller and Beneficial Owners{" "}
            {this.props.canEdit && (
              <span style={{ fontSize: 16 }}>
                ({" "}
                <Link
                  to={`/employers/${this.props.companyId}/kyb/owners`}
                  className="link"
                  style={{ paddingLeft: 0 }}
                >
                  Add More
                </Link>{" "}
                )
              </span>
            )}
          </h4>
          {this._buildOwnersSection()}
          {this.state.customError && (
            <Alert type={"error"} msg={this.state.customError} />
          )}
          <div className="submit-row">
            <Button
              name="submit"
              btnLabel="Submit for KYB"
              disabled={!this.props.canSubmit}
              onClick={() => this._preValidateData()}
            />
            <Button
              name="action"
              color="action"
              btnLabel="View KYB Status"
              disabled={!canViewStatus}
              onClick={() => this.setState({ showKycStatusModal: true })}
            />
          </div>
        </div>
      </div>
    );
  }
}

const actions = [adminConstants.REGISTER_KYB];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => ({
  error: errorSelector(state),
  isFetching: loadingSelector(state),
});

const mapDispatchToProps = {
  registerKYB,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Summary));
