import { push } from "connected-react-router";
import { get } from "lodash";

import { userConstants } from "./types";
import { userService } from "services/userService";
import ActionCreator from "utils/actionHandler";

export const registerUserRequest = (client, user) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.REGISTER_USER);
    dispatch(actionCreator.request());

    userService.register(client, user).then(
      (data) => {
        dispatch(actionCreator.success(user));
        if (data != undefined && data.signUp.id != undefined) {
          dispatch(push(`/login/${window.location.search}`));
        }
        window.analytics.track("Individual Login Created");
      },
      (error) => {
        window.analytics.track("Individual Login Failed");
        console.log("registerUserRequest Error: " + error);
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const registerAdminUserRequest = (client, user) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.REGISTER_ADMIN_USER);
    dispatch(actionCreator.request());

    userService.registerAdmin(client, user).then(
      () => {
        dispatch(actionCreator.success(user));
      },
      (error) => {
        console.log("registerAdminUserRequest Error: " + error);
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

// get the state for the now authenticated user
export const getUserState = (client, optionalAuth0User) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.USER_STATE);
    dispatch(actionCreator.request());

    return userService
      .getState(client)
      .then(
        // get the user object
        (data) => {
          if (data != undefined && data.user != undefined) {
            localStorage.setItem("user", data.user);

            if (optionalAuth0User != undefined) {
              localStorage.setItem("auth0User", optionalAuth0User);
              data.user.auth0User = optionalAuth0User;
            }

            return dispatch(actionCreator.success(data));
          } else {
            console.log("getUserState Error: data undefined.");
            return dispatch(
              actionCreator.failure("getUserState Error: data undefined.")
            );
          }
        },
        (error) => {
          console.log("getUserState Error: " + error);
          return dispatch(actionCreator.failure(error));
        }
      )
      .catch((error) => dispatch(actionCreator.failure(error)));
  };
};

export const updateIraAccountIdentityInfo = (client, identityInfo) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_ON_BOARDING_IDENTITY_UPDATE
    );
    dispatch(actionCreator.request());

    return userService.updateIraAccountIdentity(client, identityInfo).then(
      (data) => {
        dispatch(actionCreator.success(data));
        if (data != undefined) {
          window.analytics.track("IRA Account On-Boarding: Identity Info");
        }
        return data;
      },
      (error) => {
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const updateIraAccountUserCitizenship = (client, userCitizenship) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_ON_BOARDING_CITIZENSHIP_UPDATE
    );
    dispatch(actionCreator.request());

    return userService
      .updateIraAccountUserCitizenship(client, userCitizenship)
      .then(
        (data) => {
          dispatch(actionCreator.success(data));
          if (data != undefined) {
            window.analytics.track("IRA Account On-Boarding: Identity Info");
          }
          return data;
        },
        (error) => {
          dispatch(actionCreator.failure(error));
        }
      );
  };
};

export const updateIraAccountTrustedContact = (client, trustedContact) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_ON_BOARDING_TRUSTED_CONTACT_UPDATE
    );
    dispatch(actionCreator.request());

    return userService
      .updateIraAccountTrustedContact(client, trustedContact)
      .then(
        (data) => {
          dispatch(actionCreator.success(data));
          if (data != undefined) {
            window.analytics.track("IRA Account On-Boarding: Trusted Contact");
          }
          return data;
        },
        (error) => {
          dispatch(actionCreator.failure(error));
        }
      );
  };
};

export const updateIraAccountEmploymentStatus = (client, employmentStatus) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_ON_BOARDING_EMPLOYMENT_STATUS_UPDATE
    );
    dispatch(actionCreator.request());

    return userService
      .updateIraAccountEmploymentStatus(client, employmentStatus)
      .then(
        (data) => {
          dispatch(actionCreator.success(data));
          if (data != undefined) {
            window.analytics.track(
              "IRA Account On-Boarding: Employment Status"
            );
          }
          return data;
        },
        (error) => {
          dispatch(actionCreator.failure(error));
        }
      );
  };
};

export const updateIraAccountEmploymentPosition = (
  client,
  employmentPosition
) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_ON_BOARDING_EMPLOYMENT_POSITION_UPDATE
    );
    dispatch(actionCreator.request());

    return userService
      .updateIraAccountEmploymentPosition(client, employmentPosition)
      .then(
        (data) => {
          dispatch(actionCreator.success(data));
          if (data != undefined) {
            window.analytics.track(
              "IRA Account On-Boarding: Employment Position"
            );
          }
          return data;
        },
        (error) => {
          dispatch(actionCreator.failure(error));
        }
      );
  };
};

export const updateIraAccountDisclosure = (client, disclosure) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_ON_BOARDING_DISCLOSURE_UPDATE
    );
    dispatch(actionCreator.request());

    return userService.updateIraAccountDisclosure(client, disclosure).then(
      (data) => {
        dispatch(actionCreator.success(data));
        if (data != undefined) {
          window.analytics.track("IRA Account On-Boarding: Disclosure");
        }
      },
      (error) => {
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const updateIraAccountInvestmentProfile = (
  client,
  investmentProfile
) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_ON_BOARDING_INVESTMENT_PROFILE_UPDATE
    );
    dispatch(actionCreator.request());

    return userService
      .updateIraAccountInvestmentProfile(client, investmentProfile)
      .then(
        (data) => {
          dispatch(actionCreator.success(data));
          if (data != undefined) {
            window.analytics.track(
              "IRA Account On-Boarding: Investment Profile"
            );
          }
        },
        (error) => {
          dispatch(actionCreator.failure(error));
        }
      );
  };
};

export const updateIraAccountSuitability = (client, suitability) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_ON_BOARDING_INVESTMENT_SUITABILITY_UPDATE
    );
    dispatch(actionCreator.request());

    return userService.updateIraAccountSuitability(client, suitability).then(
      (data) => {
        dispatch(actionCreator.success(data));
        if (data != undefined) {
          window.analytics.track(
            "IRA Account On-Boarding: Investment Suitability"
          );
        }
      },
      (error) => {
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const updateIraAccountTermsAndSubmit = (client, terms) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_ON_BOARDING_INVESTMENT_TERMS_UPDATE
    );
    dispatch(actionCreator.request());

    return userService.updateIraAccountTermsAndSubmit(client, terms).then(
      (data) => {
        dispatch(actionCreator.success(data));
        if (data != undefined) {
          window.analytics.track("IRA Account On-Boarding: Terms and Submit");
          dispatch(push("/individual/thanks"));
        }
      },
      (error) => {
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const updateEmployerAccountTermsAndSubmit = (client, terms) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_EMPLOYER_TERMS_UPDATE
    );
    dispatch(actionCreator.request());

    return userService.updateEmployerAccountTermsAndSubmit(client, terms).then(
      (data) => {
        dispatch(actionCreator.success(data));
        if (data != undefined) {
          window.analytics.track(
            "Employer Account On-Boarding: Terms and Submit"
          );
        }
      },
      (error) => {
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const logoutUser = (userId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.USER_LOGOUT);
    dispatch(actionCreator.request());

    userService.logout(userId).then(
      () => {
        dispatch(actionCreator.success(userId));
      },
      (error) => {
        console.log("logout Error: " + error);
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const persistDocument = ({ file, docType, subDocType, metadata }) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_UPLOADED_DOCUMENT
    );
    dispatch(actionCreator.request());

    let documentObject = {
      docUrl: file.fileUrl,
      docType,
      subDocType,
      metadata,
      contentType: get(file, "file.type", "application/binary"),
      name: get(file, "file.name", "missing_file_name"),
    };

    dispatch(actionCreator.success(documentObject));
  };
};

// Confirm email address
export const confirmEmail = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.CONFIRM_EMAIL);
    dispatch(actionCreator.request());

    userService.confirmEmail(client).then(
      () => {
        dispatch(actionCreator.success());
        window.analytics.track("Verified Email");
      },
      (error) => {
        console.log("confirmEmail Error: " + error);
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export function setInitialUserLoading(isLoading) {
  return { type: userConstants.SET_INITIAL_USER_LOADING, isLoading };
}

export const getUserProfile = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.USER_PROFILE);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.getUserProfile(client)
    );
  };
};

export const updateUserProfile = (client, userProfile) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.USER_PROFILE_UPDATE);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.updateUserProfile(client, userProfile),
      { user: userProfile }
    );
  };
};

export const completeUserKyc = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.USER_KYC_COMPLETED);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.userCompletedKyc(client)
    );
  };
};

export const competeUserOnboarding = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_ON_BOARDING_COMPLETED
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.userCompletedOnboarding(client)
    );
  };
};

export const submitYellowPathDocs = (client, documents) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_SUBMIT_YELLOWPATH_DOCS
    );
    dispatch(actionCreator.request());
    return userService.updateIraYellowPathDocuments(client, documents).then(
      (data) => {
        dispatch(actionCreator.success(data));
        window.analytics.track("Submitted Yellow Path Docs");
      },
      (error) => {
        console.log("submitYellowPathDocs Error: " + error);
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const getNeededYellowPathDocs = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_NEEDED_YELLOWPATH_DOCS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.getNeededYellowPathDocs(client)
    );
  };
};

export const closeAccount = (client, accountId, reason) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.USER_CLOSE_ACCOUNT);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.closeUserAccount(client, accountId, reason)
    );
  };
};

export const changeEmail = (client, email) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.EMAIL_CHANGE);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.requestEmailChange(client, email)
    );
  };
};

export const getEmailChangeRequest = (client, email) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.GET_EMAIL_CHANGE);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.getEmailChangeRequest(client, email)
    );
  };
};

export const verifyEmailChangeRequest = (client, emailChangeRequestId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.VERIFY_EMAIL_CHANGE);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.verifyEmailChangeRequest(client, emailChangeRequestId)
    );
  };
};

export const deleteEmailChangeRequest = (client, emailChangeRequestId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.DELETE_EMAIL_CHANGE);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.deleteEmailChangeRequest(client, emailChangeRequestId)
    );
  };
};

export const getInvestmentProfileAndSuitability = (client, accountId) => {
  return () => {
    return userService
      .getInvestmentProfileAndSuitability(client, accountId)
      .then((data) => data)
      .catch((error) => {
        console.log("getInvestmentProfileAndSuitability Error: " + error);
        return {};
      });
  };
};

export const getInvestmentProfile = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.GET_INVESTMENT_PROFILE
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.getInvestmentProfile(client)
    );
  };
};

export const updateInvestmentProfileAndSuitability = (
  client,
  accountId,
  investmentProfile,
  suitability,
  isAccountScoped
) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_INVESTMENT_PROFILE_UPDATE
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.updateInvestmentProfileAndSuitability(
        client,
        accountId,
        investmentProfile,
        suitability,
        isAccountScoped
      )
    );
  };
};

export const acceptInvitation = (client, invitationCode) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_ACCEPT_INVITATION
    );
    dispatch(actionCreator.request());
    return userService.acceptInvitation(client, invitationCode).then(
      (res) => {
        dispatch(actionCreator.success(res));
        return res;
      },
      (error) => {
        console.log("acceptInvitation Error: " + error);
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const optOut = (client, inviteId, optedOut) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.OPT_OUT);
    dispatch(actionCreator.request());
    return userService.optOut(client, inviteId, optedOut).then(
      (res) => {
        dispatch(actionCreator.success(res));
        return res;
      },
      (error) => {
        console.log("optOut Error: " + error);
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const getEmployerLinkRequests = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_EMPLOYER_LINK_REQUESTS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.getEmployerLinkRequests(client)
    );
  };
};

export const getLinkedEmployers = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.USER_EMPLOYER_LINK);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.getLinkedEmployers(client)
    );
  };
};

export const deleteEmployerLink = (client, linkId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.DELETE_USER_EMPLOYER_LINK
    );
    dispatch(actionCreator.request());
    return userService.deleteEmployerLink(client, linkId).then(
      () => {
        dispatch(actionCreator.success(linkId));
      },
      (error) => {
        console.log("deleteEmployerLink Error: " + error);
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const startInvestmentProfileUpdate = (client) => {
  return async (dispatch) => {
    await userService.startInvestmentProfileUpdateRequest(client);
    return getUserState(client)(dispatch);
  };
};

export const startPortfolioUpdate = (client) => {
  return async (dispatch) => {
    await userService.startPortfolioUpdateRequest(client);
    return getUserState(client)(dispatch);
  };
};

export const exitInvestmentProfileUpdate = (client) => {
  return async (dispatch) => {
    await userService.exitInvestmentProfileUpdateRequest(client);
    return getUserState(client)(dispatch);
  };
};

export const deleteEmployerLinkRequest = (client, linkRequestId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.DELETE_USER_EMPLOYER_LINK_REQ
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.deleteEmployerLinkRequest(client, linkRequestId)
    );
  };
};

export const submitSsn = (client, ssn) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.SUBMIT_SSN);
    dispatch(actionCreator.request());
    return userService.submitSsn(client, ssn).then(
      (data) => {
        window.analytics.track("Submitted Ssn");
        return dispatch(actionCreator.success(data));
      },
      (error) => {
        console.log("Error submitting Ssn: " + error);
        return dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const submitEin = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.SUBMIT_EIN);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.submitEin(client, params),
      null,
      () => {
        window.analytics.track("Submitted EIN");
      }
    );
  };
};

export const validateEin = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.VALIDATE_EIN);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.validateEin(client, params)
    );
  };
};

export const createSupportRequest = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.CREATE_REQUEST);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.createSupportRequest(client, params)
    );
  };
};

export const clearUploadedDocuments = () => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.CLEAR_USER_UPLOADED_DOCUMENT
    );
    dispatch(actionCreator.request());
    dispatch(actionCreator.success());
  };
};

export const getRequiredUpdates = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.GET_USER_REQUIRED_STEPS
    );
    const allCreators = async () => {
      const response = await userService.getRequiredStepsRequest(client);
      await getUserState(client);
      return response;
    };
    return actionCreator.dispatchFullLifecycle(dispatch, allCreators());
  };
};

export const updateRequiredDisclosures = (client, data) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.UPDATE_REQUIRED_DISCLOSURES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.updateRequiredDisclosuresRequest(client, data)
    );
  };
};

export const updateSelectedAccountType = (client, accountType) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.UPDATE_SELECTED_ACCOUNT_TYPE
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.updateSelectedAccountType(client, accountType)
    );
  };
};

export const initializeRollover = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.INITIALIZE_ROLLOVER);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.initializeRollover(client, params)
    );
  };
};

export const submitRolloverAccountInfo = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.SUBMIT_ROLLOVER_INFO);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.submitRolloverAccountInfo(client, params)
    );
  };
};

export const addRolloverAccountDisclosure = (client, disclosure) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.ADD_ROLLOVER_ACCOUNT_DISCLOSURE
    );
    dispatch(actionCreator.request());

    return userService.addRolloverAccountDisclosure(client, disclosure).then(
      (data) => {
        dispatch(actionCreator.success(data));
        if (data != undefined) {
          window.analytics.track(
            "IRA Account On-Boarding: Rollover Disclosure"
          );
        }
      },
      (error) => {
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const updateRolloverInvestmentProfileAndSuitability = (
  client,
  accountId,
  investmentProfile,
  suitability
) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.USER_ROLLOVER_INVESTMENT_PROFILE_UPDATE
    );
    dispatch(actionCreator.request());

    return userService
      .updateRolloverInvestmentProfileAndSuitability(
        client,
        accountId,
        investmentProfile,
        suitability
      )
      .then(
        (data) => {
          dispatch(actionCreator.success(data));
          if (data != undefined) {
            window.analytics.track(
              "IRA Account On-Boarding: Rollover Investment And Suitability"
            );
          }
        },
        (error) => {
          dispatch(actionCreator.failure(error));
        }
      );
  };
};

export const updateRolloverAccountTermsAndSubmit = (client, terms) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      userConstants.UPDATE_ROLLOVER_ACCOUNT_TERMS_SUBMIT
    );
    dispatch(actionCreator.request());

    return userService.updateRolloverAccountTermsAndSubmit(client, terms).then(
      (data) => {
        dispatch(actionCreator.success(data));
        if (data != undefined) {
          window.analytics.track(
            "IRA Rollover Account On-Boarding: Terms and Submit"
          );
          dispatch(push("/individual/thanks"));
        }
      },
      (error) => {
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const getUserTermsSubmissionDate = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.GET_USER_TERMS_DATE);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.userTermsDate(client)
    );
  };
};

export const getRolloverInfo = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.GET_ROLLOVER_INFO);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      userService.getRolloverInfo(client, params)
    );
  };
};

export const cancelRollover = (client, accountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(userConstants.CANCEL_ROLLOVER);
    dispatch(actionCreator.request());
    return userService
      .cancelRollover(client, accountId)
      .then(() => dispatch(actionCreator.success(accountId)))
      .catch((error) => dispatch(actionCreator.failure(error)));
  };
};
