import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get, head, isEmpty, size } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { push } from "connected-react-router";

import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { getAllAccounts } from "actions/accountActions";
import { userConstants } from "actions/types";
import { getRequiredUpdates } from "actions/userActions";
import IconSpinner from "components/IconSpinner";
import IndividualInvestmentProfile from "pages/signUp/individual/IndividualInvestmentProfile";
import IndividualPortfolioSelection from "pages/signUp/individual/IndividualPortfolioSelection";
import CustodianDisclosure from "./CustodianDisclosure";
import { IndividualRequiredUpdatesPending } from "statics/states";
import RequiredUpdatesContributionSelection from "pages/signUp/individual/setupContribution/RequiredUpdatesContributionSelection";

class IndividualRequiredUpdates extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}),
    error: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    userState: PropTypes.string.isRequired,
    getRequiredUpdates: PropTypes.func.isRequired,
    getAllAccounts: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    fallbackAccountId: PropTypes.string,
    pendingRequiredUpdates: PropTypes.arrayOf(
      PropTypes.shape({
        entityIdentifier: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
        pendingSteps: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  };

  state = {
    loading: true,
    currentEntityIndex: 0, // Track which update (account) we're working on
  };

  componentDidMount() {
    this.props.getRequiredUpdates(this.props.client).then(() => {
      if (this.props.userState !== IndividualRequiredUpdatesPending) {
        this.props.push("/dashboard");
      }
      this.setState({ loading: false });
    });
  }

  render() {
    const { loading, currentEntityIndex } = this.state;
    const { pendingRequiredUpdates = [] } = this.props;

    if (loading) {
      return <IconSpinner centered />;
    }

    const currentUpdate = pendingRequiredUpdates[currentEntityIndex];

    if (!currentUpdate) {
      return <div />;
    }

    const firstPendingStep = head(currentUpdate.pendingSteps);

    const components = {
      INVESTMENT_PROFILE_AND_SUITABILITY: IndividualInvestmentProfile,
      PORTFOLIO_UPDATE: IndividualPortfolioSelection,
      CUSTODIAN_DISCLOSURE_QUESTION: CustodianDisclosure,
      PAYROLL_CONTRIBUTION_UPDATE: RequiredUpdatesContributionSelection,
    };

    const ActiveComponent = get(components, firstPendingStep);

    return (
      <>
        {!isEmpty(ActiveComponent) ? (
          <ActiveComponent
            updatingAccountId={
              currentUpdate.entityIdentifier.type === "BankAccount"
                ? currentUpdate.entityIdentifier.id
                : null
            }
            onComplete={() => this.handleStepCompletion(currentUpdate)}
          />
        ) : (
          <div />
        )}
      </>
    );
  }

  /**
   * Handles the completion of a required update step for an entity
   * @param {Object} currentUpdate - The current entity update being processed
   *
   * Flow:
   * 1. Fetch latest required updates
   * 2. Check if current entity still has pending steps
   * 3. If yes, refresh the component to show next step
   * 4. If no, move to next entity in the updates array
   * 5. If no more entities, redirect to dashboard
   */
  handleStepCompletion = (currentUpdate) => {
    this.props.getRequiredUpdates(this.props.client).then(() => {
      const updatedEntity = this.props.pendingRequiredUpdates.find(
        (update) =>
          update.entityIdentifier.id === currentUpdate.entityIdentifier.id
      );

      if (size(updatedEntity?.pendingSteps) > 0) {
        // Current entity still has pending steps, refresh to show next step
        this.forceUpdate();
      } else {
        // Current entity is complete, move to next entity
        this.setState(
          {
            currentEntityIndex: this.state.currentEntityIndex + 1,
          },
          () => {
            // If we've processed all entities, return to dashboard
            if (
              this.state.currentEntityIndex >=
              this.props.pendingRequiredUpdates.length
            ) {
              this.props.push("/dashboard");
            }
          }
        );
      }
    });
  };
}

const actions = [userConstants.GET_USER_REQUIRED_STEPS];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => ({
  pendingRequiredUpdates: get(state, "user.requiredUpdates.updates", []),
  userState: get(state, "user.userState.state", ""),
  error: errorSelector(state),
  isFetching: loadingSelector(state),
});

const mapDispatchToProps = {
  getRequiredUpdates,
  getAllAccounts,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualRequiredUpdates));
